import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, filter, of, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { IDpaLatestDto } from 'src/app/models/dpa-latest-dto';
import { DataProcessingAgreementService } from 'src/app/services/data-processing-agreement.service';
import { downloadBlob } from 'src/app/shared/blob-helper';
import { IDpaDialogData, IDpaDialogReturnData } from './dpa-dialog-data';

@UntilDestroy()
@Component({
    selector: 'app-dpa-dialog',
    templateUrl: './dpa-dialog.component.html',
    styleUrl: './dpa-dialog.component.scss',
    standalone: false
})
export class DpaDialogComponent implements OnInit {
  readonly #dialogRef = inject(MatDialogRef<DpaDialogComponent>);
  readonly #dpaService = inject(DataProcessingAgreementService);
  readonly data: IDpaDialogData = inject(MAT_DIALOG_DATA);

  dpaConfirmationRequested?: IDpaLatestDto;

  selectedLanguage$ = new ReplaySubject<string>(1);
  #selectedLanguage?: string;
  public get selectedLanguage(): string {
    return this.#selectedLanguage;
  }
  public set selectedLanguage(input: string) {
    this.#selectedLanguage = input;
    this.selectedLanguage$.next(input);
  }

  availableLanguages: Record<string, string> = {};
  languageLoadingState: 'loading' | 'error' | 'success' | 'noSelection' = 'noSelection';
  previewTextDpaConfirmationRequested?: string;
  optInChecked: boolean = false;

  // ui/ux
  pdfDownloading = false;

  dpaForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    lastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    client: new FormControl('', [Validators.required]),
  });

  closeViaAcceptedButton(): void {
    const dpaDialogReturn: IDpaDialogReturnData = {
      accepted: true,
      client: this.dpaForm.controls['client'].value,
      firstName: this.dpaForm.controls['firstName'].value,
      lastName: this.dpaForm.controls['lastName'].value,
      dpaLanguage: this.selectedLanguage,
      dpaUuid: this.dpaConfirmationRequested.uuid,
    };
    this.#dialogRef.close(dpaDialogReturn);
  }

  async downloadDpaTextAsPdf(): Promise<void> {
    this.selectedLanguage$
      .pipe(
        take(1),
        tap(() => (this.pdfDownloading = true)),
        switchMap((language) => this.#dpaService.getDpaTextAsPdf(language)),
        untilDestroyed(this),
      )
      .subscribe((response) => {
        this.pdfDownloading = false;
        if (response) {
          downloadBlob(response);
        }
      });
  }

  ngOnInit(): void {
    this.#dialogRef.addPanelClass(['dialog-max-size', 'dialog-nopadding']);
    this.#dpaService
      .getDpaConfirmationRequested()
      .pipe(untilDestroyed(this))
      .subscribe((dpa) => {
        this.dpaConfirmationRequested = dpa;
        this.selectedLanguage = dpa.defaultLanguage;
      });

    this.selectedLanguage$
      .pipe(
        untilDestroyed(this),
        tap(() => (this.languageLoadingState = 'loading')),
        filter(() => !!this.dpaConfirmationRequested),
        switchMap((language) => {
          const resolvedText$ = this.availableLanguages[language]
            ? of(this.availableLanguages[language])
            : this.#dpaService.getDpaTextByLanguage(this.dpaConfirmationRequested!.uuid, language);

          return combineLatest([of(language), resolvedText$]);
        }),
      )
      .subscribe(([language, text]) => {
        if (!text) {
          this.languageLoadingState = 'error';
        } else {
          this.availableLanguages[language] = text;
          this.previewTextDpaConfirmationRequested = text;
          this.languageLoadingState = 'success';
        }
      });
  }
}
