import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-help-dialog',
    templateUrl: './help-dialog.component.html',
    styleUrls: ['./help-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class HelpDialogComponent implements OnInit {
  readonly #dialogRef = inject(MatDialogRef<HelpDialogComponent>);
  readonly #domSanitizer = inject(DomSanitizer);
  readonly #translateService = inject(TranslateService);

  dialogText: SafeHtml;

  async ngOnInit(): Promise<void> {
    this.#dialogRef.addPanelClass(['dialog-nopadding']);

    const translated = await lastValueFrom(this.#translateService.get('FRAME.HELP_LOGIN'));
    this.dialogText = this.#domSanitizer.bypassSecurityTrustHtml(translated);
  }
}
