import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { IDigitalInvoiceResponse } from 'src/app/models/digital-invoice-response';
import { DigitalInvoiceState } from 'src/app/models/digital-invoice-state';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Helper } from 'src/app/shared/helper';

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss'],
    standalone: false
})
export class InvoiceComponent implements OnInit {
  readonly #router = inject(Router);
  readonly #invoiceService = inject(InvoiceService);
  readonly #translateService = inject(TranslateService);

  digitalInvoiceState: DigitalInvoiceState = DigitalInvoiceState.None;
  invoiceResponse: IDigitalInvoiceResponse | null = null;

  hash = '';
  language = '';

  constructor() {
    const params = Helper.getUrlParams(window.location.search);
    this.language = params['l'];
    this.hash = params['h'];

    if (!this.language || !this.hash) {
      this.redirectToPortal();
    }
  }

  redirectToPortal() {
    this.#router.navigateByUrl('/');
  }

  async ngOnInit(): Promise<void> {
    // für das eInvoicing gibt es nur Übersetzungen für Deutsch und Englisch
    await lastValueFrom(this.#translateService.use(this.language === 'de' ? 'de' : 'en'));

    this.#invoiceService.getInvoiceResponse(this.hash, this.language).subscribe((invoiceResponse) => {
      this.invoiceResponse = invoiceResponse;

      if (this.invoiceResponse?.code === 200) {
        this.digitalInvoiceState = DigitalInvoiceState.Success;
      } else if (this.invoiceResponse?.code === 409) {
        this.digitalInvoiceState = DigitalInvoiceState.Conflict;
      } else {
        this.digitalInvoiceState = DigitalInvoiceState.Error;
      }
    });
  }
}
