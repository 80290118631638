import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { combineLatest, lastValueFrom, Observable, timer } from 'rxjs';
import { debounce, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AssociatedBrand } from 'src/app/models/associated-brand';
import { IAuthenticationAction } from 'src/app/models/authentication-action';
import { AuthenticationResult } from 'src/app/models/authentication-result';
import { LoginInputModel } from 'src/app/models/login-input-model';
import { IOrganisationMasterData } from 'src/app/models/organisation-master-data';
import { DataProcessingAgreementService } from 'src/app/services/data-processing-agreement.service';
import { LogoService } from 'src/app/services/logo.service';
import { NotificationService } from 'src/app/services/notification.service';
import {
  DATE_FORMATS,
  DateFormat,
  DistanceUnit,
  EMAIL_PATTERN,
  getPasswordToolTip,
  getTimeZones,
  isValidPasswordInput,
  LANGUAGES,
  NumberFormat,
  TimeZoneUnit,
  USER_ACCOUNT_NAME_PATTERN,
  VAT_PATTERN,
} from 'src/app/shared/constants';
import { InstantErrorStateMatcher } from 'src/app/shared/instant-error-state-matcher';
import {
  ILocaleConfig,
  IOrganisation,
  IRegistrationModel,
  IUser,
  PressureSystem,
  TemperatureSystem,
  VolumeSystem,
  WeightSystem,
} from '../../models/registration-model';
import { AuthService } from '../../services/auth.service';
import { InvitationService } from '../../services/invitation.service';
import { IDpaDialogData, IDpaDialogReturnData } from '../dialogs/dpa-dialog/dpa-dialog-data';
import { DpaDialogComponent } from '../dialogs/dpa-dialog/dpa-dialog.component';
import { TermsOfServiceDialogComponent } from '../dialogs/terms-of-service-dialog/terms-of-service-dialog.component';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import { ILoginConfig } from '../login-dialog/login-dialog.component';

@UntilDestroy()
@Component({
    selector: 'app-invite',
    templateUrl: './invite.component.html',
    styleUrls: ['./invite.component.scss'],
    providers: [{ provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } }],
    standalone: false
})
export class InviteComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  readonly #logoService = inject(LogoService);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #invitationService = inject(InvitationService);
  readonly #authService = inject(AuthService);
  readonly #dialog = inject(MatDialog);
  readonly #translate = inject(TranslateService);
  readonly #notificationService = inject(NotificationService);
  readonly #dpaService = inject(DataProcessingAgreementService);

  loading = true;

  invite: IInviteParams;

  dpaConfirmationRequested = false;
  acceptedDpa?: IDpaDialogReturnData;

  invitingPartner: IOrganisationMasterData;
  error: string;

  waitingForRegisterResponse = false;

  tab: 'register' | 'login' = 'login';

  readonly languages: string[] = LANGUAGES;
  readonly dateFormats: DateFormat[] = DATE_FORMATS;
  readonly timeZones = getTimeZones();
  readonly distanceSystems = this.#toKeyValueList(DistanceUnit);
  readonly numberFormats = this.#toKeyValueList(NumberFormat);
  readonly pressureSystems = this.#toKeyValueList(PressureSystem);
  readonly temperatureSystems = this.#toKeyValueList(TemperatureSystem);
  readonly volumeSystems = this.#toKeyValueList(VolumeSystem);
  readonly weightSystems = this.#toKeyValueList(WeightSystem);

  passwordRestrictionsTooltip = '';

  loginSettings: ILoginConfig = {
    login: this.performLogin.bind(this),
    register: this.setTab.bind(this, 'register'),
    mode: 'LOGIN_AND_ACCEPT',
  };

  errorStateMatcher = new InstantErrorStateMatcher();

  readonly orgaIdMinLength = 4;
  readonly orgaIdMaxLength = 36;

  companyForm = new FormGroup({
    fullName: new FormControl('', [Validators.required]),
    vatNr: new FormControl('', [Validators.required, Validators.pattern(VAT_PATTERN)], [this.vatNumberValidator()]),
    country: new FormControl(null, [Validators.required]),
    street: new FormControl('', [Validators.required]),
    houseNr: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    phonePrefix: new FormControl('', [Validators.required, Validators.pattern(/^\d{1,3}$/)]),
    phone: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
    email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
  });

  userForm = new FormGroup({
    mail: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
    firstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    lastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    login: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(100),
      Validators.pattern(USER_ACCOUNT_NAME_PATTERN),
    ]),
    organisation: new FormControl(
      '',
      [
        Validators.required,
        Validators.minLength(this.orgaIdMinLength),
        Validators.maxLength(this.orgaIdMaxLength),
        Validators.pattern(USER_ACCOUNT_NAME_PATTERN),
      ],
      [
        (control) =>
          timer(500).pipe(
            switchMap(() => this.#authService.organisationNameAllowed(control.value)),
            map((allowed) => (allowed ? null : { alreadyExists: true })),
          ),
      ],
    ),
    password: new FormControl('', [
      Validators.required,
      (control) => (isValidPasswordInput(control.parent?.value?.login, control.value) ? null : { syntax: true }),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      (control) => (isValidPasswordInput(control.parent?.value?.login, control.value) ? null : { syntax: true }),
      (control) => (control.parent?.value?.password !== control.value ? { notSame: true } : null),
    ]),
  });

  userSettingsForm = new FormGroup({
    language: new FormControl('', [Validators.required]),
    dateFormat: new FormControl<DateFormat>('DE', [Validators.required]),
    numberFormat: new FormControl<NumberFormat>(NumberFormat.DOT, [Validators.required]),
    timeZoneUnit: new FormControl<TimeZoneUnit>(TimeZoneUnit.UTC, [Validators.required]),
    distanceSystem: new FormControl<DistanceUnit>(DistanceUnit.KILOMETER, [Validators.required]),
    weightSystem: new FormControl<WeightSystem>(WeightSystem.METRIC, [Validators.required]),
    pressureSystem: new FormControl<PressureSystem>(PressureSystem.METRIC, [Validators.required]),
    volumeSystem: new FormControl<VolumeSystem>(VolumeSystem.LITER, [Validators.required]),
    temperatureSystem: new FormControl<TemperatureSystem>(TemperatureSystem.CELSIUS, [Validators.required]),
  });

  confirmationForm = new FormGroup({
    termsOfService: new FormControl(false, [Validators.requiredTrue]),
    dataProcessingAgreement: new FormControl(true, [Validators.requiredTrue]),
  });
  currentStep = 0;

  filteredOptions: Observable<{ countryShort: string; countryFull: string }[]>;
  languageCodes: [{ countryShort: string; countryFull: string[] }];
  nocountrys = false;

  openHelpDialog(): void {
    this.#dialog.open(HelpDialogComponent, {
      width: '600px',
      restoreFocus: false,
    });
  }

  stepChange(event: any): void {
    this.currentStep = event.selectedIndex;

    if (this.currentStep === 2) {
      this.userSettingsForm.markAsTouched();
    }
  }

  openTermsOfServiceDialog(): void {
    this.#dialog.open(TermsOfServiceDialogComponent, {
      restoreFocus: false,
    });
  }

  openDpaDialog(): void {
      this.#dialog.open<DpaDialogComponent, IDpaDialogData>(DpaDialogComponent, {
        restoreFocus: false,
        data: {
          mode: 'read',
        },
      });
  }

  async performLogin(model: LoginInputModel): Promise<AuthenticationResult | null | undefined> {
    let result: IAuthenticationAction;

    if (this.loginSettings.mode === 'LOGIN_OR_REGISTER_AND_ACCEPT') {
      result = await lastValueFrom(this.#authService.loginConnectInvite(model, this.invite.relationUuid, this.invite.token));
    } else if (this.loginSettings.mode === 'LOGIN_AND_ACCEPT') {
      result = await lastValueFrom(this.#authService.loginAcceptInvite(model, this.invite.relationUuid, this.invite.token));
    } else {
      result = await lastValueFrom(this.#authService.login(model));
    }

    if (result.result === AuthenticationResult.Redirect && result.redirectUrl) {
      window.location.replace(result.redirectUrl);
      return null;
    }

    return result?.result;
  }

  async performRegistration(): Promise<void> {
    if (this.acceptedDpa === undefined && this.dpaConfirmationRequested) {
      return;
    }

    this.waitingForRegisterResponse = true;

    const registration: IRegistrationModel = {
      user: {
        ...this.userForm.value,
        localeConfig: this.userSettingsForm.value as ILocaleConfig,
      } as IUser,
      organisation: {
        ...this.companyForm.value,
      } as IOrganisation
    };

    if (this.acceptedDpa !== undefined) {
      registration.acceptedDpa = {
        language: this.acceptedDpa.dpaLanguage,
        uuid: this.acceptedDpa.dpaUuid,
      }
    }

    registration.organisation.country = this.companyForm.controls['country'].value.countryShort;
    registration.organisation.tel = '+' + this.companyForm.controls['phonePrefix'].value + this.companyForm.controls['phone'].value;

    const result = await lastValueFrom(this.#authService.registerAcceptInvite(registration, this.invite.relationUuid, this.invite.token));

    if (result.result === AuthenticationResult.Redirect && result.redirectUrl) {
      window.location.replace(result.redirectUrl);
      return;
    } else if (result.result === AuthenticationResult.NotAnymoreAllowed) {
      this.#notificationService.openSnackBar('LOGIN.INVITATION_EXPIRED', false);
    } else if (result.result === AuthenticationResult.Error) {
      this.#notificationService.openSnackBar('LOGIN.INVITATION_RESULT-' + result.result, false);
    } else {
      this.#notificationService.openSnackBar('LOGIN.INVITATION_INVALID', false);
    }

    this.waitingForRegisterResponse = false;
  }

  setTab(tab: 'login' | 'register'): void {
    if (tab === 'register' && this.dpaConfirmationRequested) {
      this.#dialog
        .open<DpaDialogComponent, IDpaDialogData, IDpaDialogReturnData | undefined>(DpaDialogComponent, {
          data: {
            mode: 'accept',
          },
        })
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((acceptedDpa) => {
          if (acceptedDpa?.accepted) {
            // continue registration
            this.acceptedDpa = acceptedDpa;
            this.userForm.controls['firstName'].setValue(acceptedDpa.firstName);
            this.userForm.controls['lastName'].setValue(acceptedDpa.lastName);
            this.companyForm.controls['fullName'].setValue(acceptedDpa.client);
            this.tab = tab;
          } else {
            // go back to login view
            this.tab = 'login';
          }
        });
    }

    this.tab = tab;
  }

  private vatNumberValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      timer(500).pipe(
        switchMap(() => this.#authService.vatPreflightCheck(control.value)),
        map((ok) =>
          ok
            ? null
            : {
                vatExists: true,
              },
        ),
      );
  }

  private filterCountryInput(value: string): { countryShort: string; countryFull: string }[] {
    const filterValue = value.toLowerCase();

    const filteredOptions = this.languageCodes.reduce((previous, current) => {
      const index = current.countryFull.findIndex((full) => full.toLowerCase().includes(filterValue));
      if (index > -1) {
        previous.push({ countryShort: current.countryShort, countryFull: current.countryFull[index] });
      }
      return previous;
    }, []);

    this.nocountrys = filteredOptions.length === 0;
    this.companyForm.controls['country'].markAsDirty();
    return filteredOptions;
  }

  showcountryFull(country): string {
    return country.countryFull;
  }

  checkValidCountry(): void {
    if (typeof this.companyForm.controls['country'].value === 'string') {
      this.companyForm.controls['country'].setErrors({ countryError: true });
      return null;
    }
    const filterValue = this.companyForm.controls['country'].value.countryFull.toLowerCase();

    if (!this.languageCodes.find((country) => country.countryFull.find((full) => full.toLowerCase() === filterValue))) {
      this.companyForm.controls['country'].setErrors({ countryError: true });
    } else {
      this.companyForm.controls['country'].setErrors(null);
    }
  }

  #toKeyValueList(o: {}): { key: string; value: number }[] {
    return Object.keys(o)
      .filter((item) => {
        return !isNaN(Number(item));
      })
      .map((unit) => {
        return { key: o[unit], value: +unit };
      });
  }

  async ngOnInit(): Promise<void> {
    let isInitial = true;
    this.filteredOptions = combineLatest([
      this.#invitationService.getCountries().pipe(tap((c) => (this.languageCodes = c))),
      this.companyForm.controls['country'].valueChanges.pipe(startWith({ countryShort: 'DE', countryFull: 'Germany' })),
    ]).pipe(
      debounce(() => (isInitial ? timer(0) : timer(500))),
      tap(() => (isInitial = false)),
      map(([, value]) => {
        if (typeof value === 'string') {
          return this.filterCountryInput(value ?? '');
        } else {
          return this.filterCountryInput(value.countryFull ?? '');
        }
      }),
    );

    this.companyForm.controls['country'].setValue({ countryShort: 'DE', countryFull: 'Germany' });

    this.passwordRestrictionsTooltip = await getPasswordToolTip(this.#translate);

    this.userSettingsForm.patchValue({
      language: this.languages[0],
      dateFormat: this.dateFormats[0],
    });

    this.userForm.controls['login'].valueChanges.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.userForm.controls['password'].updateValueAndValidity();
    });

    this.#activatedRoute.params
      .pipe(
        map((p) => p as IInviteParams),
        tap((p) => {
          this.loading = true;
          this.invite = p;
        }),
        switchMap((p) => combineLatest([this.#invitationService.getOrganisationMasterData(p.relationUuid, p.token), this.#dpaService.getDpaConfirmationRequested()])),
        untilComponentDestroyed(this),
      )
      .subscribe(([httpResponse, dpa]) => {
        if (dpa !== null) {
          this.dpaConfirmationRequested = true;
        }

        if (!httpResponse.ok) {
          this.error = httpResponse.status === 410 ? 'LOGIN.INVITATION_EXPIRED' : 'LOGIN.INVITATION_INVALID';
          this.loginSettings.mode = 'LOGIN';
        } else {
          this.invitingPartner = httpResponse.body;

          if (this.invitingPartner.associatedBrand !== undefined) {
            this.#logoService.setLogoUrl(AssociatedBrand[this.invitingPartner.associatedBrand]);
          }

          this.loginSettings.mode = this.invite.type === 'new' ? 'LOGIN_OR_REGISTER_AND_ACCEPT' : 'LOGIN_AND_ACCEPT';

          if (this.invite.type === 'new' && this.invite.email) {
            this.userForm.setValue({ ...this.userForm.value, mail: this.invite.email } as any);
          }
        }

        this.loading = false;
      });

    this.confirmationForm.controls.dataProcessingAgreement.valueChanges.pipe(untilDestroyed(this)).subscribe((v) => {
      if (!v) {
        // keep the checkbox always checked, because the user already
        // accepted the DPA at the very beginning of the registration process
        this.confirmationForm.controls.dataProcessingAgreement.setValue(true);
      }
    });
  }
}

interface IInviteParams {
  type: 'new' | 'existing';
  relationUuid: string;
  token: string;
  email: string;
}
