import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import dompurify from 'dompurify';

@Pipe({
    name: 'safeHtml',
    pure: true,
    standalone: false
})
export class SafeHtmlPipe implements PipeTransform {
  readonly #sanitizer = inject(DomSanitizer);

  transform(value: string | undefined, allowTags = true, allowBreak = false, allowLinks = false): SafeHtml {
    if (!value) {
      return '';
    }

    return this.#sanitizer.bypassSecurityTrustHtml(SafeHtmlPipe.purify(value, allowTags, allowBreak, allowLinks));
  }

  static purify(value: string, allowTags: boolean, allowBreak: boolean, allowLinks = false): string {
    let config: { ALLOWED_TAGS?: string[] } = {};

    if (!allowTags) {
      config = { ALLOWED_TAGS: ['div', 'span', 'mat-icon', 'button', 'svg', 'path'] };

      if (allowBreak) {
        config.ALLOWED_TAGS?.push('br');
      }

      if (allowLinks) {
        config.ALLOWED_TAGS?.push('a');

        dompurify.addHook('afterSanitizeAttributes', function (node) {
          // set all elements owning target to target=_blank
          if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener noreferrer');
          }
        });
      }
    }

    return dompurify.sanitize(value, config);
  }
}
