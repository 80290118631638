import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { IDpaLatestDto } from '../models/dpa-latest-dto';

@Injectable({
  providedIn: 'root',
})
export class DataProcessingAgreementService {
  readonly #http = inject(HttpClient);
  /**
   * Tries to fetch a dpa with a publish date smaller then today.
   * Returns null if no dpa has been published yet.
   * Returns undefined if there was an error when trying to load the dpa.
   *
   * If null is returned there is no need to open the dpa dialog in 'accept' state.
   */
  getDpaConfirmationRequested(): Observable<IDpaLatestDto | null | undefined> {
    return this.#http.get<IDpaLatestDto>('api/account/GetConfirmationRequested').pipe(catchError(() => of(undefined)));
  }

  getDpaTextByLanguage(dpaUuid: string, language: string): Observable<string | undefined> {
    return this.#http
      .get<string>(`api/account/GetConfirmationRequestedPreview/${dpaUuid}/${language}`)
      .pipe(catchError(() => of(undefined)));
  }

  /**
   * Fetches the text of the data_processing_agreement_localized row that is tied to the data_processing_agreement row where the column superseded_by has the value null.
   * @param language determines which exact row to read the value of the text column for
   * @returns
   */
  getDpaTextAsPdf(language: string): Observable<HttpResponse<any> | undefined> {
    return this.#http
      .get(`api/account/getconfirmationrequesteddownload/${language}`, {
        responseType: 'blob',

        observe: 'response',
      })
      .pipe(catchError(() => of(undefined)));
  }
}
