import { Directive, ElementRef, Input, Renderer2, inject } from '@angular/core';

@Directive({
    selector: '[appButtonLoading]',
    standalone: false
})
export class ButtonLoadingDirective {
  readonly #renderer = inject(Renderer2);
  readonly #hostElement = inject(ElementRef);

  _loading: boolean = false;

  @Input()
  get appButtonLoading(): boolean {
    return this._loading;
  }
  set appButtonLoading(value: boolean) {
    this._loading = value;

    this.#hostElement.nativeElement.disabled = value;

    if (value) {
      this.#renderer.addClass(this.#hostElement.nativeElement, 'spinner');
    } else {
      this.#renderer.removeClass(this.#hostElement.nativeElement, 'spinner');
    }
  }
}
