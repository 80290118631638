import { Component, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    standalone: false
})
export class SnackbarComponent {
  readonly data: SnackbarData = inject(MAT_SNACK_BAR_DATA);
  readonly snackbarRef = inject(MatSnackBarRef<SnackbarComponent>);
}

export class SnackbarData {
  message: string;
  messageParameters?: any;
}
