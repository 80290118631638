import { Component, inject, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AuthenticationResult } from 'src/app/models/authentication-result';
import { Helper } from 'src/app/shared/helper';
import { TokenLoginModel } from '../../models/token-login-model';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
    standalone: false
})
export class RedirectComponent implements OnInit {
  readonly #authService = inject(AuthService);

  async ngOnInit(): Promise<void> {
    const params = Helper.getUrlParams(window.location.search);

    const returnUrl = params['returnUrl'];
    let registrationToken = params['registrationToken'];

    if (!registrationToken && returnUrl) {
      const returnUrlParams = Helper.getUrlParams(returnUrl);
      registrationToken = returnUrlParams['registrationToken'] ?? null;
    }

    if (registrationToken) {
      const result = await this.tryLoginWithToken(registrationToken, returnUrl);
      if (result) {
        return;
      }
    }

    window.location.replace(window['portalUrl']);
  }

  async tryLoginWithToken(registrationToken: string, returnUrl: string): Promise<boolean> {
    const tlm = new TokenLoginModel();
    tlm.registrationToken = registrationToken;
    tlm.returnUrl = returnUrl;
    const result = await lastValueFrom(this.#authService.tryLoginWithRegistrationToken(tlm));

    if (result.result === AuthenticationResult.Redirect && result.redirectUrl) {
      window.location.replace(result.redirectUrl);
      return true;
    }

    return false;
  }
}
