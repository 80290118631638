import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IAuthenticationAction } from 'src/app/models/authentication-action';
import { AuthenticationResult } from 'src/app/models/authentication-result';
import { LoginInputModel } from 'src/app/models/login-input-model';
import { TokenLoginModel } from '../../models/token-login-model';
import { AuthService } from '../../services/auth.service';
import { Helper } from '../../shared/helper';
import { ILoginConfig, LoginDialogComponent } from '../login-dialog/login-dialog.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit, AfterViewInit {
  readonly #authService = inject(AuthService);

  error: string | undefined;

  @ViewChild(LoginDialogComponent)
  loginDialogComponent: LoginDialogComponent | undefined;

  private returnUrl: string | undefined;

  loginSettings: ILoginConfig = {
    login: this.performLogin.bind(this),
    mode: 'LOGIN',
  };

  async performLogin(m: LoginInputModel): Promise<AuthenticationResult | null | undefined> {
    const result: IAuthenticationAction = await lastValueFrom(this.#authService.login(m));

    if (result.result === AuthenticationResult.Redirect && result.redirectUrl) {
      window.location.replace(result.redirectUrl);
      return null;
    }
    return result?.result;
  }

  async tryLoginWithToken(registrationToken: string, returnUrl: string): Promise<void> {
    const tlm = new TokenLoginModel();
    tlm.registrationToken = registrationToken;
    tlm.returnUrl = returnUrl;
    const result: IAuthenticationAction = await lastValueFrom(this.#authService.tryLoginWithRegistrationToken(tlm));

    if (result.result === AuthenticationResult.Redirect && result.redirectUrl) {
      window.location.replace(result.redirectUrl);
      this.loginDialogComponent?.setError(undefined);
    } else {
      this.loginDialogComponent?.setError(LoginDialogComponent.getLoginErrorText(result.result));
    }
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.loginDialogComponent && this.returnUrl) {
      this.loginDialogComponent.returnUrl = this.returnUrl;
    }
  }

  async ngOnInit(): Promise<void> {
    const params = Helper.getUrlParams(window.location.search);

    this.returnUrl = params['ReturnUrl'] ?? null;
    const returnUrlParams = Helper.getUrlParams(this.returnUrl);

    if (!!returnUrlParams['timeout']) {
      this.error = 'LOGIN.SESSION_TIMEOUT';
    }

    const registrationToken = params['registrationToken'] ?? returnUrlParams['registrationToken'] ?? null;
    if (registrationToken) {
      await this.tryLoginWithToken(registrationToken, this.returnUrl);
    }
  }
}
